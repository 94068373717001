import React, { useEffect, useState, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { StudyContext, BookingContext } from 'packages/booking-contexts';
import CheckoutForm from './CheckoutForm';

const StripeElement = ({ show, openAlert, transaction, locationID, onChange }) => {
  const { stripeTokens } = useContext(BookingContext);
  const [stripePromise, setStripePromise] = useState(null);
  const getStripeToken = async () => {
    const stripeToken = stripeTokens[locationID];
    if (stripeToken && !stripePromise) {
      const stripe = await loadStripe(stripeToken);
      setStripePromise(stripe);
    }
  };
  useEffect(() => {
    getStripeToken();
  }, [stripeTokens]);
  return (
    <>
      {show && (
        <Elements stripe={stripePromise}>
          <CheckoutForm openAlert={openAlert} transaction={transaction} onChange={onChange} />
        </Elements>
      )}
    </>
  );
};

const StripeElements = ({ openAlert, transaction, onChange }) => {
  const { locations } = useContext(BookingContext);
  const { selectedLocation } = useContext(StudyContext);

  return locations.map(({ locationID }) => (
    <StripeElement
      key={`${locationID}-stripe-element`}
      show={locationID === selectedLocation.locationID}
      openAlert={openAlert}
      transaction={transaction}
      locationID={locationID}
      onChange={onChange}
    />
  ));
};
export default StripeElements;
