import React, { useState, useContext } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import { formatCurrency } from 'packages/formatters';
import {
  colors,
  Button,
  CheckboxInput,
  CurrencyInput,
  LoadingSpinner,
  TextInput,
  SelectInput,
} from 'shared-library';

import { StudyContext, PiiContext, BookingContext } from 'packages/booking-contexts';
import { Row, Column } from '../Layout';
import { CountryTextInput, StripeElementOnly } from './PaymentTextInputs';

import { createToken } from './stripeFunctions';
import { PaymentContext } from '../PaymentContext';
import { fetchAppsResource } from 'packages/apis';

const urlParams = new URLSearchParams(window.location.search);
const isEM124 = urlParams.get('EM-124') === 'true' || new Date() > new Date('2025-03-03');

const CheckoutForm = ({ openAlert, transaction, onChange }) => {
  const elements = useElements();
  const stripe = useStripe();
  const { load, total, recordPayment } = useContext(PaymentContext);
  const [isUsingPersonalAddress, setUsingPersonalAddress] = useState(false);
  const [pii, setPii] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
  });
  const { piiContext } = useContext(PiiContext);
  const { selectedLocation, studyId, skuID } = useContext(StudyContext);
  const currency = selectedLocation?.currency?.currency;
  const [charge, setCharge] = useState(window.location.href.includes('payment') ? '1' : '0.5');
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const [isProcessingPayment, setProcessingPayment] = useState(false);
  const [transactionForm, setTransactionForm] = useState(transaction);
  const { isTemporaryHold, setIsTemporaryHold } = useContext(BookingContext);

  const handleCheck = () => {
    if (!isUsingPersonalAddress) {
      setUsingPersonalAddress(true);
      const { firstname, lastname, address, city, state, country, postalCode } = piiContext;
      const name = `${firstname} ${lastname}`;
      return setPii({
        name,
        address,
        city,
        state,
        country,
        postalCode,
      });
    }
    setUsingPersonalAddress(false);
    setPii({
      name: '',
      address: '',
      city: '',
      state: '',
      country: null,
      postalCode: '',
    });
  };

  const getTotalAmount = () => {
    if (Number(total) < 0) {
      return '0';
    }
    if (isEM124) {
      if (!isCustomAmount) {
        return Number(total);
      }
    } else {
      if (charge !== '0') {
        return Number(charge) * Number(total);
      }
    }
    return transactionForm.amount || '';
  };

  const postPayment = async (token) => {
    const derivedTotal = getTotalAmount();
    const { id, card } = token;
    const { transaction: paymentTransactionResponse, code } = await recordPayment(
      {
        details: {
          stripeToken: id,
          cardType: card && card.brand,
          cardLastFour: card && card.last4,
        },
        skuID,
        locationID: selectedLocation.locationID,
        paymentType: 'stripe',
        amount: derivedTotal,
      },
      onChange,
    ).catch((e) => {
      openAlert('Payment Processing Error', e.response.data.toString());
    });
    if (code === 200) {
      await load();

      if (isTemporaryHold) {
        await fetchAppsResource('/calendar/convert-temporary-hold', 'POST', { studyId });
      }

      openAlert(
        'Payment Successful',
        `Successfully charged ${formatCurrency(derivedTotal, currency)} to ${
          card.brand
        } card ending in ${card.last4}.`,
      );
    } else {
      openAlert('Payment Processing Error', paymentTransactionResponse.memo);
    }
  };

  const handleProcessPayment = async () => {
    if (!studyId || studyId === 'None') {
      openAlert('Please complete location, time, and patient details before processing payment');
      return;
    }
    if (!pii.name) {
      openAlert('Card Error', "Missing card holder's name");
      return;
    }
    if (!pii.city) {
      openAlert('Card Error', "Missing card holder's city");
      return;
    }
    if (!pii.address) {
      openAlert('Card Error', "Missing card holder's address");
      return;
    }
    if (!pii.state) {
      openAlert('Card Error', "Missing card holder's state");
      return;
    }
    if (!pii.country) {
      openAlert('Card Error', "Missing card holder's country");
      return;
    }
    if (!pii.postalCode) {
      openAlert('Card Error', "Missing card holder's postal code");
      return;
    }
    setProcessingPayment(true);
    const { error, token } = await createToken(pii, elements, stripe);
    if (error) {
      openAlert('Card Error', error);
    }
    if (token) {
      await postPayment(token);
    }
    setProcessingPayment(false);
  };

  const chargeOptions = [
    { value: '0', label: 'Custom Amount' },
    {
      value: '0.5',
      label: '50% of Balance',
    },
    {
      value: '1',
      label: '100% of Balance',
    },
  ];

  return (
    <>
      <Row>
        <Column width="50%">
          <CheckboxInput
            id="getPII"
            label="Use same name, address details as above"
            name="getPII"
            checked={isUsingPersonalAddress}
            onChange={handleCheck}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <TextInput
            id="address"
            label="Address"
            placeholder="123 Example Road"
            key="address"
            required
            value={pii.address}
            onChange={(value) => setPii({ ...pii, address: value })}
          />
        </Column>
        <Column>
          <CountryTextInput
            required
            onChange={(option) => setPii({ ...pii, country: option.value })}
            value={pii.country}
          />
        </Column>
        <Column>
          <TextInput
            id="state"
            label="State / Province"
            placeholder="Alaska"
            key="state"
            value={pii.state}
            required
            onChange={(value) => setPii({ ...pii, state: value })}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <TextInput
            id="city"
            label="City"
            placeholder="Exampletown"
            key="city"
            value={pii.city}
            required
            onChange={(value) => setPii({ ...pii, city: value })}
          />
        </Column>
        <Column>
          <TextInput
            id="postalCode"
            label="Postal Code"
            placeholder="V6B 000"
            key="postalCode"
            value={pii.postalCode}
            required
            onChange={(value) => setPii({ ...pii, postalCode: value })}
          />
        </Column>
        <Column />
      </Row>
      <Row>
        <Column>
          <TextInput
            id="name"
            label="Cardholder Name"
            placeholder="Jenny Rosen"
            value={pii.name}
            key="name"
            required
            onChange={(value) => setPii({ ...pii, name: value })}
          />
        </Column>
        <Column>
        {!isEM124 ? (
          <SelectInput
            label="Amount Percentage"
            options={chargeOptions}
            defaultValue={chargeOptions.filter((s) => s.value === charge)}
            onChange={(option) => {
              setCharge(option.value);
              if (option.value === "0") {
                setTransactionForm({ ...transactionForm, amount: 0 });
              }
            }}
          />
        ) : (
          <div style={{ marginTop: "35px", marginBottom: "0px" }}>
            <CheckboxInput
              id="customAmount"
              label="Enter Custom Amount"
              name="customAmount"
              checked={isCustomAmount}
              onChange={() => {
                setIsCustomAmount(!isCustomAmount);
                if (!isCustomAmount) {
                  setTransactionForm({ ...transactionForm, amount: 0 });
                }
              }}
            />
          </div>
        )}
        </Column>
        <Column>
          <CurrencyInput
            label="Total Amount"
            value={getTotalAmount()}
            suffix={` ${currency}`}
            displayType={isEM124 ? (isCustomAmount ? "input" : "text") : charge === "0" ? "input" : "text"}
            fixedDecimalScale
            decimalScale={2}
            allowNegative={false}
            placeholder="0.00"
            onChange={(values) => {
              if (values.value < parseFloat(total)) {
                setTransactionForm({ ...transactionForm, amount: values.value });
              } else {
                setTransactionForm({ ...transactionForm, amount: total });
              }
            }}
          />
        </Column>
      </Row>
      <StripeElementOnly />
      <Row>
        <Column>
          <Button
            disabled={isProcessingPayment}
            onClick={handleProcessPayment}
            variant="primary"
            size="small"
          >
            {isProcessingPayment ? (
              <LoadingSpinner color={colors.white} size="small" />
            ) : (
              'Process Payment'
            )}
          </Button>
        </Column>
        <Column>
          <img alt="" src="https://d3t6a5i8xdf95t.cloudfront.net/images/stripe-solid-dark.svg" />
        </Column>
      </Row>
    </>
  );
};

CheckoutForm.propTypes = {
  openAlert: PropTypes.func,
  transaction: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default CheckoutForm;
