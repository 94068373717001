import { CardNumberElement } from '@stripe/react-stripe-js';
import { formatCountryAsTwoLetterCode } from 'packages/formatters';

export const createStripeToken = async (pii, elements, stripe) => {
  try {
    const card = elements.getElement(CardNumberElement);
    const { error, token } = await stripe.createToken(card, {
      name: pii.name,
      address_line1: pii.address,
      address_city: pii.city,
      address_state: pii.state,
      address_country: formatCountryAsTwoLetterCode(pii.country),
      address_zip: pii.postalCode,
    });
    if (error) {
      return { error };
    }
    return { token };
  } catch (error) {
    return { error };
  }
};

export const createToken = async (pii, elements, stripe) => {
  try {
    const { error, token } = await createStripeToken(pii, elements, stripe);
    if (error) {
      return { error: error.message };
    }
    return { token };
  } catch (error) {
    return { error };
  }
};
